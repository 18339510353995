//effect snake in gallery

! function(a) {
    a.fn.snakeify = function(b) {
        var c = a.extend({
            inaccuracy: 30,
            speed: 200
        }, b);
        this.find('[data-snake]').css({
            top: -9999999
        }), this.mouseenter(function(b) {
            const d = a(this),
                e = d.find('[data-snake]'),
                f = d.offset(),
                g = b.pageX - f.left,
                h = b.pageY - f.top;
            e.css({
                top: 0,
                left: 0,
                width: d.width(),
                height: d.height()
            }), g > d.width() - c.inaccuracy ? e.css({
                top: 0,
                left: d.width()
            }) : g < c.inaccuracy ? e.css({
                top: 0,
                left: -d.width()
            }) : h > d.width() - c.inaccuracy ? e.css({
                top: d.width(),
                left: 0
            }) : h < c.inaccuracy && e.css({
                top: -d.width(),
                left: 0
            }), e.animate({
                top: 0,
                left: 0
            }, c.speed)
        }), this.mouseleave(function(b) {
            const d = a(this),
                e = d.find('[data-snake]'),
                f = d.offset(),
                g = b.pageX - f.left,
                h = b.pageY - f.top;
            g <= 0 && e.animate({
                top: 0,
                left: -d.width()
            }, c.speed), g >= d.width() && e.animate({
                top: 0,
                left: d.width()
            }, c.speed), h <= 0 && e.animate({
                left: 0,
                top: -d.height()
            }, c.speed), h >= d.height() && e.animate({
                left: 0,
                top: d.height()
            }, c.speed)
        })
    }
}(jQuery);

jQuery(document).ready(function($){

    function tense_realization_navi() {
      var $imgs = $('.realization__item');                  // Store all images
      var $buttons = $('#buttons_design');                   // Store buttons element
      var tagged = {};                                // Create tagged object

      $imgs.each(function() {                         // Loop through images and
        var img = this;                               // Store img in variable
        var tags = $(this).data('tags');              // Get this element's tags

        if (tags) {                                   // If the element had tags
          tags.split(',').forEach(function(tagName) { // Split at comma and

            if (tagged[tagName] == null) {            // If object doesn't have tag
              tagged[tagName] = [];                   // Add empty array to object
            }
            tagged[tagName].push(img);                // Add the image to the array
          });
        }
      });

      $('<button/>', {                                 // Create empty button
        text: 'Wszystkie',                              // Add text 'show all'
        class: 'active',                               // Make it active
        click: function() {                            // Add onclick handler to
          $(this)                                      // Get the clicked on button
            .addClass('active')                        // Add the class of active
            .siblings()                                // Get its siblings
            .removeClass('active');                    // Remove active from siblings
          $imgs.hide().slideDown(500);                 // Show all images
        }
      }).appendTo($buttons);                           // Add to buttons

      $.each(tagged, function(tagName) {               // For each tag name
        $('<button/>', {                               // Create empty button
          text: tagName + ' (' + tagged[tagName].length + ')', // Add tag name
          click: function() {                          // Add click handler
            $(this)                                    // The button clicked on
              .addClass('active')                      // Make clicked item active
              .siblings()                              // Get its siblings
              .removeClass('active');                  // Remove active from siblings
            $imgs                                      // With all of the images
              .hide()                                  // Hide them
              .filter(tagged[tagName])                 // Find ones with this tag
              .fadeIn(500);                                // Show just those images
          }
        }).appendTo($buttons);                         // Add to the buttons
      });
    }

    tense_realization_navi();

    $(".realization__snake").snakeify({
        speed: 200
    });
});
